import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: {
      id?: string
      databaseId?: number
      path?: string
      title?: string
      seo: any
      flexContent: any
      newsdetail: any
    }
  }
  location?: any
}

export const NewsContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { databaseId, newsdetail, flexContent, seo },
  },
  location = {},
}) => (
  <Layout>
    <SEO seo={seo} />
    <NewsContext.Provider value={{ details: newsdetail, id: databaseId }}>
      <FlexContentHandler
        prefix="post_Flexcontent"
        fields={flexContent}
        location={location}
      />
    </NewsContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query postById($id: String!) {
    page: wpPost(id: { eq: $id }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
